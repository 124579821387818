<template>
  <div class="Sidebar">
    <transition @before-enter="enterAnimation" @leave="leaveAnimation">
      <Resume :resume="Checkout.totals" :type="'small'" @click.native="toggleResumeFull"/>
    </transition>
    <div class="Sidebar__Content" :class="{'expanded': isResumeFullOpen}" v-if="Checkout.products">
      <div class="Sidebar__Inner">
        <Promotions />
        <ProductList :products="Checkout.products" :method="ActiveShippingMethod" />
        <DiscountForm v-if="Checkout.activeStep !== 10"/>
        <CheckoutMessages v-if="Checkout.messages" :messages="Checkout.messages" :type="null"/>
        <Resume :resume="Checkout.totals" :type="'full'"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
// import EventBus from '@/helpers/eventBus'
import Promotions from '@/components/_promotions/Promotions.vue'
import ProductList from '@/components/_sidebar/ProductList.vue'
import DiscountForm from '@/components/_sidebar/DiscountForm.vue'
import CheckoutMessages from '@/components/_structure/CheckoutMessages.vue'
import Resume from '@/components/_sidebar/Resume.vue'
import Animation from '@/services/animation'

export default {
  name: 'Sidebar',
  components: {
    Promotions,
    ProductList,
    DiscountForm,
    CheckoutMessages,
    Resume
  },
  data () {
    return {
      isResumeFullOpen: !!this.$route.query.show_resume
    }
  },
  created: function () {
    this.isResumeFullOpen = this.AppConfig.components.resume.settings.show_resume_open
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'ActiveShippingMethod'])
  },

  methods: {
    ...mapActions(['initPromotionsEvents']),
    toggleResumeFull () {
      this.isResumeFullOpen = !this.isResumeFullOpen
    },
    enterAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .from(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    },
    leaveAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .to(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    }
  }
}
</script>
