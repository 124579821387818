import axios from 'axios'

class Location {
    states = [
      {
        uf: 'AC',
        name: 'Acre'
      },
      {
        uf: 'AL',
        name: 'Alagoas'
      },
      {
        uf: 'AP',
        name: 'Amapá'
      },
      {
        uf: 'AM',
        name: 'Amazonas'
      },
      {
        uf: 'BA',
        name: 'Bahia'
      },
      {
        uf: 'CE',
        name: 'Ceará'
      },
      {
        uf: 'DF',
        name: 'Distrito Federal'
      },
      {
        uf: 'ES',
        name: 'Espírito Santo'
      },
      {
        uf: 'GO',
        name: 'Goías'
      },
      {
        uf: 'MA',
        name: 'Maranhão'
      },
      {
        uf: 'MT',
        name: 'Mato Grosso'
      },
      {
        uf: 'MS',
        name: 'Mato Grosso do Sul'
      },
      {
        uf: 'MG',
        name: 'Minas Gerais'
      },
      {
        uf: 'PA',
        name: 'Pará'
      },
      {
        uf: 'PB',
        name: 'Paraíba'
      },
      {
        uf: 'PR',
        name: 'Paraná'
      },
      {
        uf: 'PE',
        name: 'Pernambuco'
      },
      {
        uf: 'PI',
        name: 'Piauí'
      },
      {
        uf: 'RJ',
        name: 'Rio de Janeiro'
      },
      {
        uf: 'RN',
        name: 'Rio Grande do Norte'
      },
      {
        uf: 'RS',
        name: 'Rio Grande do Sul'
      },
      {
        uf: 'RO',
        name: 'Rondônia'
      },
      {
        uf: 'RR',
        name: 'Roraíma'
      },
      {
        uf: 'SC',
        name: 'Santa Catarina'
      },
      {
        uf: 'SP',
        name: 'São Paulo'
      },
      {
        uf: 'SE',
        name: 'Sergipe'
      },
      {
        uf: 'TO',
        name: 'Tocantins'
      }
    ]
    zipInfo = (zip) => {
      return new Promise((resolve, reject) => {
        let _this = this
        axios.get('https://viacep.com.br/ws/' + zip + '/json/')
          .then(res => {
            resolve({
              street: res.data.logradouro,
              neighborhood: res.data.bairro,
              city: res.data.localidade,
              state: _this.states.filter(state => {
                return state.uf === res.data.uf
              })[0]
            })
          })
          .catch(err => {
            reject(err)
          })
      })
    }
    findZipCode = ({ state, city, street }) => {
      return new Promise((resolve, reject) => {
        if (state && city && street) {
          let _this = this

          let stateCode = _this.states.filter(stateItem => {
            return stateItem.name === state
          })[0].uf

          axios.get(`https://viacep.com.br/ws/${stateCode}/${city}/${street}/json/`)
            .then(res => {
              let addressOBJ = res.data.map((address) => {
                return {
                  zip: address.cep,
                  street: address.logradouro,
                  neighborhood: address.bairro,
                  city: address.localidade,
                  state: _this.states.filter(state => {
                    return state.uf === address.uf
                  })[0]
                }
              })
              resolve({
                results: addressOBJ
              })
            })
            .catch(err => {
              reject(err)
            })
        } else {
          reject(new Error('Os campos X, P, T, O são obrigatórios.'))
        }
      })
    }
    geoNames = () => axios.get('http://www.geonames.org/childrenJSON?geonameId=3469034')
}
export default new Location()
